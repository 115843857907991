/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Layout from "../components/layout";
import { Link } from "gatsby";
import Container from "../components/container";
import { graphql } from "gatsby";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import ProductTeaserSmall from "../components/entities/product/productTeaserSmall";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import PlaceHolder from "../images/placeholder3.jpg";
import VideoIcon from "../images/video-icon.svg";
import BrochureIcon from "../images/brochure-icon.svg";
import NotesIcon from "../images/notes-icon.svg";
import QuoteIcon from "../images/quote-icon.svg";
import PicturesIcon from "../images/pictures-icon.svg";
import FactsIcon from "../images/facts-icon.svg";

const ResourceIcon = (data) => {
  const resource = data.data;
  switch (resource) {
    case "video":
      return (
        <div className="resource-icon">
          <img src={VideoIcon}></img>
        </div>
      );
      break;
    case "brochure":
      return (
        <div className="resource-icon">
          <img src={BrochureIcon}></img>
        </div>
      );
      break;
    case "application-notes":
      return (
        <div className="resource-icon">
          <img src={NotesIcon}></img>
        </div>
      );
      break;
    case "testimonials-success-stories":
      return (
        <div className="resource-icon">
          <img src={QuoteIcon}></img>
        </div>
      );
      break;
    case "pictures":
      return (
        <div className="resource-icon">
          <img src={PicturesIcon}></img>
        </div>
      );
      break;
    case "facts-figures":
      return (
        <div className="resource-icon">
          <img src={FactsIcon}></img>
        </div>
      );
      break;
    default:
      return null;
  }
};

const ProductResourceByType = (props) => {
  console.log(props);
  const bgImage = convertToBgImage(
    props.data.heroImage.childImageSharp.gatsbyImageData
  );
  const ResourceTypeLabel = (resourceType) => {
    console.log(resourceType);
    let label = "";
    switch (resourceType.resourceType) {
      case "brochure":
        label = "Brochures and Pricelist";
        break;
      case "video":
        label = "Videos";
        break;
      case "application-notes":
        label = "Application Notes";
        break;
      case "pictures":
        label = "Pictures";
        break;
      case "testimonials-success-stories":
        label = "Testimonials & Success Stories";
        break;
      case "facts-figures":
        label = "Facts & Figures";
        break;
      default:
        label = "";
    }
    return (
      <h2
        sx={{
          margin: "0px",
          fontSize: "24px",
          lineHeight: "24px",
        }}
      >
        {label}
      </h2>
    );
  };
  return (
    <Layout type="resource-product-index-outer default-outer">
      <SEO
        title={props.data.product.nodes[0].headline + " Resources"}
        lang="en"
        description=""
      ></SEO>

      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>
              {props.data.product.nodes[0].headline} Resources
            </h1>
            <div
              sx={{
                a: {
                  color: "white",
                },
              }}
            >
              <Link to={"/"}>Home</Link>
              <span
                sx={{
                  color: "white",
                  margin: "0px 10px",
                }}
              >
                >
              </span>
              <Link to={"/resources"}>Resources</Link>
            </div>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
      <Container className="resources-product-index-container">
        <ul
          sx={{
            padding: "40px 0px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {props.data.product_type.group.map((resource_type, index) => (
            <li
              key={index}
              sx={{
                marginBottom: "40px",
                listStyle: "none",
                width: ["100%", "48%"],
                display: "flex",
              }}
            >
              <Link
                sx={{
                  display: "flex",
                  width: "100%",
                  textDecoration: "none",
                  color: "black",
                  border: "thin solid #ccc",
                  padding: "20px 25px 20px 25px",
                  borderRadius: "7px",
                  boxSizing: "border-box",
                  alignItems: "center",
                  ":hover": {
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                  },
                  ".resource-container": {
                    display: "flex",
                    h2: {
                      margin: "0px 0px 0px 0px",
                      fontSize: "24px",
                      lineHeight: "24px",
                    },
                  },
                  ".resource-icon": {
                    width: "80px",
                    marginRight: "20px",
                    textAlign: "center",
                    img: {
                      height: "60px",
                    },
                  },
                  ".resource-title-description": {
                    width: "calc(100% - 100px)",
                  },
                  i: {
                    color: "primary",
                    fontSize: "14px",
                    margin: "1px 0px 5px 0px",
                    display: "block",
                  },
                }}
                to={
                  "/resources/" +
                  props.pageContext.slug +
                  "/" +
                  resource_type.fieldValue
                }
              >
                <ResourceIcon data={resource_type.fieldValue} />
                <ResourceTypeLabel
                  resourceType={resource_type.fieldValue}
                ></ResourceTypeLabel>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  );
};

export default ProductResourceByType;

export const resourceByTypeQuery = graphql`
  query productResourceByTypeQuery($slug: String!) {
    blueArrowRight: file(name: { eq: "Right-Blue-Caret" }) {
      publicURL
    }
    blueArrowLeft: file(name: { eq: "Left-Blue-Caret" }) {
      publicURL
    }
    heroImage: file(name: { eq: "freedom" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    product: allSanityProduct(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        headline
      }
    }
    product_type: allSanityResource(
      filter: { product: { slug: { current: { eq: $slug } } } }
    ) {
      group(field: resource_type) {
        nodes {
          slug {
            current
          }
        }
        fieldValue
      }
    }
  }
`;
